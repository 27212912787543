@use 'sass:math';
@import '../../../base/assets/scss/_base/config';
@import '../../../base/assets/scss/_base/mixins';

// This breakpoint is used for the DLs mWeb design opposed to the standard xs grid breakpoints.
// Reason being that the DL is at it's smallest below this size, and the GAM/Benji iframe's media queries are based on the DL size opposed to the window size.
$breakpoint-mweb: 515px;
$border-radius-desktop: 24px;
$border-radius-mweb: 16px;
$image-aspect-padding-desktop: 75%; // 4:3
$image-aspect-padding-mweb: 50%; // 2:1
$image-vertical-gutter: 18px;
$image-left-gutter: 16px;
$image-border-radius: 12px;
$image-vertical-gutter-s: 23px;
$image-left-gutter-s: 12px;
$image-vertical-gutter-m: 12px;
$image-left-gutter-m: 12px;
$image-vertical-gutter-l: 14px;
$image-left-gutter-l: 13px;
$line-clamp-map: (
  "#{map-get($breakpoints, xxs)}": 3,
  "#{$breakpoint-mweb}": 4,
  "#{map-get($breakpoints, xs)}": 5,
  "#{map-get($breakpoints, s)}": 5,
  "#{map-get($breakpoints, m)}": 6,
  "#{map-get($breakpoints, l)}": 7,
  "#{map-get($breakpoints, xl)}": 9,
  "#{map-get($breakpoints, xxl)}": 10
);

@mixin LineClampMediaQueryMap($map) {
  @each $key, $value in $map {
    @media screen and (min-width: $key) {
      -webkit-line-clamp: $value;
    }
  }
}

.dl__outer-wrapper {
  position: relative;
  border-radius: $border-radius-desktop;
  box-shadow: $fluffy-pancake;
  background-color: white;
  font-family: $font-family-base;
  text-align: left;
  overflow: hidden;
  margin-bottom: 44px !important;

  &.dl-mobile {
    margin-right: -10px;
    margin-left: -10px;
    width: calc(100% + 20px);
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0 !important;
    margin-top: 20px;
    overflow: hidden;

    #aol-wafer-dl {
      .dl-caption {
        display: none;
      }

      .dl__slide {
        padding-right: 12px;


        // This overlay class is specifically for 'edge' rapid tracking on mweb
        &-overlay {
          background: transparent;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          right: 10px;
          left: 0;
          top: 0;
          z-index: 1;
          border-radius: $border-radius-mweb;
        }
        &.active{
          .dl__slide-overlay {
            visibility: hidden;
          }
        }

        &--body-inner, .dl-benji > div, .dl-taboola > div{
          background-color: $white;
          box-shadow: $fluffy-pancake;
          border-radius: $border-radius-mweb;
          overflow: hidden;
        }
        &--body-inner{
          display: flex;
          flex-direction: column;
        }

        div[data-wf-dl-placeholder] {
          display: block;
        }
      }

      div[data-slide-type] {
        margin-bottom: 48px;
      }
    }
  }
}

#aol-wafer-dl {
  // Hidden by default until wafer-rapid initializes
  &[style*="visibility:hidden"]{
    visibility: hidden;
    div[data-slide-type]{
      display: none;
    }
  }

  .slide-btn {
    $button-width: 40px;
    border: none;
    position: absolute;
    z-index: 10;
    height: 84px;
    width: $button-width;
    top: calc(50% - 52px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, .1), 0 4px 8px rgba(0, 0, 0, .1);
    background-color: rgba(35, 42, 49, .5);

    svg {
      fill: white;
      height: 19px;
    }

    &.next-btn {
      right: 0;
      border-top-left-radius: $button-width;
      border-bottom-left-radius: $button-width;
      padding-left: 9px;

    }

    &.prev-btn {
      left: 0;
      border-top-right-radius: $button-width;
      border-bottom-right-radius: $button-width;
      padding-right: 9px;
    }
  }

  .slides {
    line-height: 0;
    margin: 0;
    padding: 0;
    will-change: transform;
    transform: translateZ(0);
    display: flex;
    list-style: none;
  }

  .dl__slide {
    position: relative;

    &--link {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
    }

    &--img-wrap {
      position: relative;
      overflow: hidden;
      background-color: white;
      height: 0;
      width: 100%;
      padding-top: $image-aspect-padding-mweb;

      img {
        position: absolute;
        object-fit: contain;
        object-position: left;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .dl__slide__img--mweb{
        display: block;
      }
      .dl__slide__img--desktop {
        display: none;
      }
    }

    &--inner-wrapper {
      height: 100%;
      padding: 14px 20px 26px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .dl_provider {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        min-width: 0; // Needed for text-overflow: ellipsis to work in flex container

        &__img {
          height: 16px;
          width: 16px;
          margin-right: 8px;
          fill: $backwater;
        }

        &__name {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__name, .dl__slide__count {
          white-space: nowrap;
          color: $battleship;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          margin: 0;
        }
      }

      .dl__slide__title {
        color: $batcave;
        font-family: $font-family-poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        height: 60px; // 3 x line-height
        margin: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        order: 1;
        @include LineClampMediaQueryMap($line-clamp-map);

        &--link {
          text-decoration: none;
          font: inherit;
          color: inherit;
          display: block;
        }
      }

      .dl__slide__linkline {
        display: none;
      }

      .dl__slide__caption {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      .dl__sponsor {
        display: flex;
        flex-wrap: nowrap;
        font-style: normal;
        min-width: 0;
        font-weight: 500;
        color: $battleship;
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 10px;

        span, a {
          color: inherit;
          font: inherit;
          white-space: nowrap;
        }

        a {
          text-decoration: none;
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .dl__slide__count {
          font: inherit;
          white-space: nowrap;
          margin: 0;
        }
      }

      .dl__slide--spacer {
        display: none;
      }
    }

    .dl-benji{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;

      div {
        top: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;

        iframe {
          height: 100%;
          width: 100%;
          border: none;
        }
      }
    }

    .dl-taboola {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;

      //div {
      //  top: 0;
      //  position: absolute;
      //  left: 0;
      //  bottom: 0;
      //  right: 0;
      //  height: 100%;
      //  width: 100%;
      //}
    }


  }

  .dl-caption {
    display: none;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 39.4%;

    .dl-caption-wrapper {
      height: 100%;

      > div {
        height: 100%;
        padding-bottom: 58px;
      }
    }
  }

  .indicators {
    display: flex;
    justify-content: center;
    align-items: center;

    &-lbl {
      font-family: $font-family-poppins;
      font-size: 10px;
      color: $scooter;
      font-weight: 600;
      min-width: 40px;
      white-space: nowrap;
      text-align: center;
    }

    &-container {
      bottom: 20px;
      left: 20px;
      position: absolute;
    }

    .buttons {
      white-space: nowrap;
      height: 35px;
      border-radius: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: static;
      background-color: white;
      box-shadow: $light-elevation;


      .play-pause-btn {
        margin: 0px -4px 0 8px;
        padding: 0;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: white;

        &-play {
          display: inline-block;
        }

        &-pause {
          display: none;
        }

        &.dl-playing {
          .play-pause-btn {
            &-play {
              display: none;
            }

            &-pause {
              display: inline-block;
            }
          }
        }

        svg {
          margin: 0 auto;
          height: 10px;
        }
      }

      .prev-btn, .next-btn {
        padding: 0 12px;
        margin: 0;
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
        position: relative;
        border: none;
        background-color: white;
        border-radius: 100%;

        &:after {
          content: "";
          display: block;
          width: 1px;
          background-color: $dirty_seagull;
          position: absolute;
          right: 0;
          top: 5px;
          bottom: 5px;
        }
      }

      .prev-btn {
        margin-right: 10px;
      }

      .next-btn {
        margin-left: 10px;

        &:after {
          left: 0;
          right: auto;
        }
      }

      svg {
        fill: $scooter;
        height: 15px;
      }
    }
  }

  div[data-slide-type="benji"],
  div[data-slide-type="taboola"]
  {
    .dl-caption {
      top: unset;

      .dl-caption-wrapper {
        display: none;
      }
    }

    // [START] Benji/Iframe specific styles
    // The following styles are hacks needed due to the fact that these slides are in an iframe

    @media screen and (min-width: 776px) and (max-width: (map-get($breakpoints, s) - 1px)) {
      .dl-caption .indicators-container {
        left: 52px;
      }
    }

    @media screen and (min-width: 1108px) and (max-width: (map-get($breakpoints, m) - 1px)) {
      .dl-caption .indicators-container {
        left: 40px;
      }
    }
    // [END] Benji/Iframe specific styles
  }
}

@media screen and (min-width: $breakpoint-mweb) {
  .dl__outer-wrapper{
    &.dl-mobile{
      #aol-wafer-dl{
        .dl__slide{
          div[data-wf-dl-placeholder] {
            width: 39.4%;
          }
          &--body-inner{
            flex-direction: row;
          }
        }
      }
    }
  }
  #aol-wafer-dl {
    .slide-btn {
      $button-width: 40px;
      background-color: rgba(255, 255, 255, 0.5);
      top: calc(50% - $button-width);

      svg {
        fill: $picasso;
      }

      &:hover {
        background-color: $picasso;

        svg {
          fill: white;
        }
      }
    }

    .dl__slide {
      padding-right: 0;

      div[data-wf-dl-placeholder] {
        display: none;
      }

      &--link {
        width: 60.6%;
      }

      &--img-wrap {
        &--outer{
          padding: $image-vertical-gutter 0 $image-vertical-gutter $image-left-gutter;
        }
        border-radius: $image-border-radius;
        padding-top: $image-aspect-padding-desktop;

        .dl__slide__img--mweb{
          display: none;
        }
        .dl__slide__img--desktop {
          display: block;
        }
      }

      &--inner-wrapper {
        padding: 28px 40px 0 20px;

        .dl_provider {
          margin-bottom: 7px;

          &__name {
            color: $backwater;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4;

          }
        }

        .dl__slide__title {
          font-size: 14px;
          height: auto;
          order: unset;
        }

        .dl__slide__linkline {
          display: block;
          text-decoration: none;
          color: $picasso;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .dl__sponsor {
          color: $dolphin;
          font-size: 10px;
          line-height: 1;
          margin-bottom: 0;
        }

        .dl__slide--spacer {
          display: block;
          flex-basis: 16px;
          flex-shrink: 100;
        }
      }
    }

    .dl-caption {
      display: block;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, s)) {
  $img-link-width: 58.17%;
  #aol-wafer-dl {
    .dl__slide {
      &--link {
        width: $img-link-width
      }

      &--img-wrap {
        &--outer{
          padding: $image-vertical-gutter-s 0 $image-vertical-gutter-s $image-left-gutter-s;
        }
      }

      &--inner-wrapper {
        .dl__slide__title {
          font-size: 13px;
          line-height: 20px;
        }

        .dl__slide__linkline {
          font-size: 10px;
        }
      }

      div[data-wf-dl-placeholder]{
        width: 100% - $img-link-width;
      }
    }

    .dl-caption {
      width: 100% - $img-link-width;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, m)) {
  $img-link-width: 61.38%;
  #aol-wafer-dl {
    .dl__slide {
      &--link {
        width: $img-link-width;
      }

      &--img-wrap {
        &--outer{
          padding: $image-vertical-gutter-m 0 $image-vertical-gutter-m $image-left-gutter-m;
        }
      }

      &--inner-wrapper {
        .dl__slide__title {
          font-size: 16px;
          line-height: 24px;
        }

        .dl__slide__linkline {
          font-size: 11px;
        }
      }

      div[data-wf-dl-placeholder]{
        width: 100% - $img-link-width;
      }
    }

    .dl-caption {
      width: 100% - $img-link-width;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, l)) {
  $img-link-width: 60.75%;
  #aol-wafer-dl {
    .dl__slide {
      &--link {
        width: 60.75%;
      }

      &--img-wrap {
        &--outer{
          padding: $image-vertical-gutter-l 0 $image-vertical-gutter-l $image-left-gutter-l;
        }
      }

      &--inner-wrapper {
        .dl__slide__title {
          font-size: 18px;
          line-height: 24px;
        }

        .dl__slide__linkline {
          font-size: 12px;
        }
      }

      div[data-wf-dl-placeholder]{
        width: 100% - $img-link-width;
      }
    }

    .dl-caption {
      width: 100% - $img-link-width;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xl)) {
  $img-link-width: 64.98%;
  #aol-wafer-dl {
    .dl__slide {
      &--link {
        width: $img-link-width;
      }

      &--img-wrap {
        &--outer{
          padding: 0;
        }
        border-radius: 0;
      }

      div[data-wf-dl-placeholder]{
        width: 100% - $img-link-width;
      }
    }

    .dl-caption {
      width: 100% - $img-link-width;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, xxl)) {
  $img-link-width: 65%;
  #aol-wafer-dl {
    .dl__slide {
      &--link {
        width: $img-link-width;
      }

      &--inner-wrapper {
        padding-left: 32px;
        .dl__slide__title {
          font-size: 20px;
          line-height: 28px;
        }
      }
      div[data-wf-dl-placeholder]{
        width: 100% - $img-link-width;
      }
    }

    .dl-caption {
      width: 100% - $img-link-width;
      .dl-caption-wrapper {
        > div {
          padding-bottom: 67px;
        }
      }
    }

    .indicators {
      &-container{
        left: 32px;
      }
      &-lbl{
        font-size: 13px;
      }
      .buttons {
        height: 44px;
      }
    }
  }
}
